
import Icon from "@/components/Icon.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppHeader",
  components: {
    Icon,
  },
});

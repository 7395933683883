
import AppHeader from "@/components/AppHeader.vue";
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: { AppHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const i18n = useI18n();

    onMounted(() => {
      processUrlQueryParams();
    });

    let processUrlQueryParams = async () => {
      await router.isReady();
      console.log("query", route.query);
      if (route.query.lang) {
        console.log("store.getters.locale", store.getters.locale);
        store.commit("setLocale", route.query.lang);
        i18n.locale.value = store.getters.locale;
      }
    };
  },
});

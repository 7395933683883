import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { isProd } from "../config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "promo",
    component: () => import("../views/Promo.vue"),
    props: true,
  },
  {
    path: "/NY2023",
    name: "promo1",
    component: () => import("../views/Promo.vue"),
    props: (route) => ({
      email: route.query.email,
      coupon: isProd ? "kSc5bvSL" : "MC2022",
    }),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    props: true,
  },
  {
    path: "/success",
    name: "Success",
    props: (route) => ({
      plan: route.query.plan,
      email: route.query.email,
    }),
    component: () => import("../views/Success.vue"),
  },
  {
    path: "/cancel",
    name: "Cancel",
    props: (route) => ({
      email: route.query.email,
    }),
    component: () => import("../views/Cancel.vue"),
  },
  {
    path: "/pro-exists",
    name: "Pro Exists",
    props: (route) => ({
      plan: route.query.plan,
      store: route.query.store,
      email: route.query.email,
    }),
    component: () => import("../views/ProExists.vue"),
  },
  {
    path: "/error",
    name: "Error",
    props: (route) => ({
      code: route.query.code,
    }),
    component: () => import("../views/Error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import { vfmPlugin } from "vue-final-modal";

import { initializeApp, getApp } from "firebase/app";
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { firebaseConfig } from "@/config";

initializeApp(firebaseConfig);

const app = getApp();

// connectFunctionsEmulator(getFunctions(app), "localhost", 5003);

import messages from "./lng";

const i18n = createI18n({
  legacy: false,
  locale: store.getters.locale,
  fallbackLocale: "en",
  messages: messages,
});

import "@/assets/scss/index.scss";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
createApp(App).use(vfmPlugin).use(i18n).use(router).use(store).mount("#app");

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app__header header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createVNode(_component_icon, {
          name: "logo",
          width: 150,
          height: 16
        })
      ]),
      _: 1
    })
  ]))
}
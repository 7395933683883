import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import messages from "../lng";

const languages = Object.keys(messages);

export default createStore({
  state: {
    locale: "en",
    loading: false,
  },
  getters: {
    locale(state) {
      return state.locale;
    },
    loading: (state) => {
      return state.loading;
    },
  },
  mutations: {
    setLocale(state, payload) {
      if (languages.includes(payload)) {
        state.locale = payload;
      }
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      paths: ["locale"],
    }),
  ],
});

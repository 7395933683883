export const isProd = process.env.NODE_ENV === "production";

export const firebaseConfig = isProd
  ? {
      apiKey: "AIzaSyAFvaZhWQz_-zZcAsonTWsEoDfelAH__ls",
      authDomain: "crypttoapp.firebaseapp.com",
      databaseURL: "https://crypttoapp.firebaseio.com",
      projectId: "crypttoapp",
      storageBucket: "crypttoapp.appspot.com",
      messagingSenderId: "790655484178",
      appId: "1:790655484178:web:1a6d199def6b291e03f3cb",
      measurementId: "G-VZKMMSTDX6",
    }
  : {
      apiKey: "AIzaSyAEQoPPruWn6c07K6L9nx03UIWncp_uwVU",
      authDomain: "crypttoio.firebaseapp.com",
      databaseURL: "https://crypttoio.firebaseio.com",
      projectId: "crypttoio",
      storageBucket: "crypttoio.appspot.com",
      messagingSenderId: "705353637550",
      appId: "1:705353637550:web:23d1946ad01b36d276d73f",
      measurementId: "G-55L3PT0JLR",
    };
